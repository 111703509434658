<template>
  <v-container fluid tag="section" style="margin-top: 30px">  
    <v-row>
      <v-col>
        <eventsTable />
      </v-col>
    </v-row>
    
  </v-container>
</template>


<script>
export default {
  components: {
    eventsTable: () => import("./widgets/eventsTable")
  }
};
</script>